import imports from '../imports';
import pages from '../pages.json';

const setMetaTags = ({ title, description }) => {
  title = (title && title + ' • Structured Data Hub') || 'Structured Data Hub • The best way to work with structured data';
  description = (description && description) || 'The best way to work with structured data.'

  document.querySelector('title').innerHTML = title;
  document.querySelector('meta[property="og:title"]').setAttribute('content', title);
  document.querySelector('meta[property="og:description"]').setAttribute('content', description);
};

export default (pathname, cb) => {
  const pageCore = pages.main[pathname] || pages.sandbox[pathname] || pages.main[404];
  const pageDetails = imports.pages[pageCore.page];

  history.pushState({}, null, pathname);

  setMetaTags(pageCore);

  imports.components[pageDetails.component].then((page) => {
    page.render();
    cb && cb();
  });
}
