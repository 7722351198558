import renderPage from "./utils/renderPage";

state = {
  initialized: false,
  version: '1572855172'
};

window.onpopstate = () => {
  renderPage(document.location.pathname);
};

renderPage(window.location.pathname, () => {
  state.initialized = true;
});
