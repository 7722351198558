module.exports = {
  "main": {
    "/": {
      "page": "Homepage"
    },
    "404": {
      "title": "Not Found",
      "description": "this is 404",
      "page": "NotFound"
    }
  },
  "sandbox": {
    "/article": {
      "title": "Article",
      "description": "Make your article show up in the top stories carousel with a headline and preview images.",
      "page": "Article"
    },
    "/book": {
      "title": "Book",
      "description": "Sell more books directly from search results. Customize how your books will appear by using this snippet.",
      "page": "Book"
    },
    "/breadcrumb": {
      "title": "Breadcrumb",
      "description": "Add a navigation element showing your website hierarchy. Your website link will look better in search results.",
      "page": "Breadcrumb"
    },
    "/carousel": {
      "title": "Carousel",
      "description": "Configure interactive carousel for search results, that will show articles and other items from your website.",
      "page": "Carousel"
    },
    "/corporate-contact": {
      "title": "Corporate Contact",
      "description": "Add contact information that will be visible in the Google Knowledge panel. This will increase your credibility.",
      "page": "CorporateContact"
    },
    "/course": {
      "title": "Course",
      "description": "TODO: Educational courses that appear in a provider-specific list. Courses can include the course title, provider, and a short description.",
      "page": "Course"
    },
    "/critic-review": {
      "title": "Critic Review",
      "description": "TODO: A snippet from a longer review article that a single editor has created, curated, or compiled for a publisher. Critic reviews can be about Book, Movie, and Local business.",
      "page": "CriticReview"
    },
    "/dataset": {
      "title": "Dataset",
      "description": "TODO: Large data sets that appear in Google Dataset Search.",
      "page": "Dataset"
    },
    "/employer-aggregate-rating": {
      "title": "Employer Aggregate Rating",
      "description": "TODO: An evaluation of a hiring organization compiled from many users that's displayed in the job search experience on Google.",
      "page": "EmployerAggregateRating"
    },
    "/event": {
      "title": "Event",
      "description": "TODO: An interactive rich result that shows a list of organized events, such as concerts or art festivals, that people may attend at a particular time and place.",
      "page": "Event"
    },
    "/fact-check": {
      "title": "Fact Check",
      "description": "TODO: A summarized version of a credible site's evaluation of a claim made by others.",
      "page": "FactCheck"
    },
    "/faq": {
      "title": "FAQ",
      "description": "TODO: A Frequently Asked Question (FAQ) page contains a list of questions and answers pertaining to a particular topic.",
      "page": "FAQ"
    },
    "/how-to": {
      "title": "How-to",
      "description": "TODO: A How-to walks users through a set of steps to successfully complete a task, featuring video, images, and text.",
      "page": "HowTo"
    },
    "/job-posting": {
      "title": "Job Posting",
      "description": "TODO: An interactive rich result that allows job seekers to find a job. The job search experience on Google can feature your logo, reviews, ratings, and job details.",
      "page": "JobPosting"
    },
    "/job-training": {
      "title": "Job Training",
      "description": "TODO: An interactive rich result that helps job seekers and prospective students find a job training program.",
      "page": "JobTraining"
    },
    "/livestream": {
      "title": "Livestream",
      "description": "TODO: A live badge that displays on video thumbnails for videos that are live now or are about to stream live.",
      "page": "Livestream"
    },
    "/local-business-listing": {
      "title": "Local Business Listing",
      "description": "TODO: Business details displayed in the Google Knowledge Graph, including open hours, ratings, and directions.",
      "page": "LocalBusinessListing"
    },
    "/logo": {
      "title": "Logo",
      "description": "TODO: Your organization's logo in search results and Google Knowledge Graph.",
      "page": "Logo"
    },
    "/movie": {
      "title": "Movie",
      "description": "TODO: The movie carousel helps users explore lists of movies on Google Search (for example, \"best movies of 2019\"). You can provide details about the movies, such as the title of each movie, director information, and images.",
      "page": "Movie"
    },
    "/occupation": {
      "title": "Occupation",
      "description": "TODO: Salary estimate information, such as salary ranges and region-based salary averages for job types, displayed in the job search experience on Google.",
      "page": "Occupation"
    },
    "/product": {
      "title": "Product",
      "description": "TODO: Information about a product, including price, availability, and review ratings.",
      "page": "Product"
    },
    "/qa": {
      "title": "Q&A",
      "description": "TODO: Q&A Pages are web pages that contain data in a question and answer format, which is one question followed by its answers.",
      "page": "QA"
    },
    "/recipe": {
      "title": "Recipe",
      "description": "TODO: Recipes that display as an individual rich result or part of a host carousel.",
      "page": "Recipe"
    },
    "/review-snippet": {
      "title": "Review Snippet",
      "description": "TODO: A short excerpt of a review or a rating from a review website, usually an average of the combined rating scores from reviewers. A review snippet can be about a Book, Recipe, Movie, Product, Local business, and Software App.",
      "page": "ReviewSnippet"
    },
    "/sitelinks-searchbox": {
      "title": "Sitelinks Searchbox",
      "description": "TODO: A search box that is scoped to your website when it appears as a search result.",
      "page": "SitelinksSearchbox"
    },
    "/software-app": {
      "title": "Software App",
      "description": "TODO: Information about a software app, including an aggregate rating, description of the app, any offers associated with the app, and more.",
      "page": "SoftwareApp"
    },
    "/speakable": {
      "title": "Speakable",
      "description": "TODO: Allow search engines and other applications to identify news content to read aloud on Google Assistant-enabled devices using text-to-speech (TTS).",
      "page": "Speakable"
    },
    "/subscription-and-paywalled-content": {
      "title": "Subscription and Paywalled Content",
      "description": "TODO: Indicate paywalled content on your site to help Google differentiate paywalled content from the practice of cloaking, which violates our guidelines.",
      "page": "SubscriptionAndPaywalledContent"
    },
    "/top-places-list": {
      "title": "Top Places List",
      "description": "TODO: The Google Top Places List displays themed lists from authoritative publishers so that users can browse through selections from a trusted source as they decide where to go.",
      "page": "TopPlacesList"
    },
    "/video": {
      "title": "Video",
      "description": "TODO: Video information in search results, with the option to play the video.",
      "page": "Video"
    }
  }
}
;