import { h } from 'preact';
import { useEffect, useRef } from 'preact/hooks';

import isPath from '../utils/isPath';
import renderPage from '../utils/renderPage';
import prefetch from '../utils/prefetch';

export default ({ href, children }) => {
  const link = useRef(null);

  const onMouseOver = (event) => {
    if (!isPath(href)) {
      return;
    }

    prefetch(href);
  }

  const onClick = (event) => {
    if (!isPath(href)) {
      return;
    }

    renderPage(href);
    event.preventDefault();
  }

  useEffect(() => {
    link.current.addEventListener('click', onClick);
    link.current.addEventListener('mouseover', onMouseOver);

    return () => {
      link.current.removeEventListener('click', onClick);
      link.current.removeEventListener('mouseover', onMouseOver);
    }
  }, [href, link]);

  return <a href={href} ref={link}>{children}</a>;
}
