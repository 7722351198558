const imports = {
  components: {
    Homepage: import('./components/Homepage'),
    NotFound: import('./components/NotFound'),
    Sandbox: import('./components/Sandbox')
  },
  pages: {
    Homepage: require('./pages/Homepage'),
    NotFound: require('./pages/NotFound'),
    Article: require('./pages/Article')
  }
};

export default imports;
